import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Country } from '../../../../libs/shared'
import secrets from '../config/secrets'

declare const window: Window & { dataLayer?: any[] }

const { COUNTRY } = secrets

const PromoPage = () => {
  const isBolivia = COUNTRY === Country.BOLIVIA

  useEffect(() => {
    if (!isBolivia) {
      navigate('/')

      return
    }

    function initDataLayer(s: string, l: string, i: string) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

      const f = document.getElementsByTagName(s)[0]
      const j = document.createElement(s) as HTMLScriptElement
      const dl = l !== 'dataLayer' ? `&l=${l}` : ''

      j.async = true
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`

      if (f.parentNode) f.parentNode.insertBefore(j, f)
    }

    initDataLayer('script', 'dataLayer', 'GTM-TDNJX9P')
  }, [])

  if (!isBolivia) return <div />

  return (
    <div>
      <Helmet>
        <title>Promocion Mi CocaCola</title>
        <meta
          httpEquiv="refresh"
          content="0;url='https://urldefense.com/v3/__https://api.whatsapp.com/send/?phone=59178889933&amp;text&amp;app_absent=0__;!!BiPlgCE7GXGj!ilt7kqEP9hM7B0HQ8VtgFnbF-02VZ0eL2HEqW5nckgpbNlIKlVol8Q0KtH40rf-8C7x6jA$'"
        />
      </Helmet>
      <noscript>
        <iframe
          title="No script GTM"
          src="https://www.googletagmanager.com/ns.html?id=GTM-TDNJX9P"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </div>
  )
}

export default PromoPage
